body {
  flex-flow: column;
  height: auto;
  display: flex;
}

header {
  flex-flow: column;
  width: 100%;
  height: 95vh;
  display: flex;
}

.hero-section {
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 70%;
  display: flex;
  position: relative;
  top: 18%;
}

.main-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.logo {
  z-index: 0;
  width: 15%;
  margin-bottom: 1.5rem;
}

.menubars-use, #menubars-svg, #show-menu-button {
  display: none;
}

.menu {
  z-index: 7;
  background-color: #fff;
  width: 100%;
  height: 15%;
  display: flex;
  position: fixed;
}

.menu__ul {
  background-color: #fff;
  flex-flow: row;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-nav-tab {
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
}

.main-nav-tab:hover {
  cursor: pointer;
}

.attrib, a {
  text-align: center;
  color: #4e4e4e;
  text-transform: uppercase;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
  display: flex;
}

.attrib:hover {
  color: #989898;
}

.attrib-cart {
  text-align: center;
  color: #000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  font-family: Raleway, sans-serif;
  font-size: 1.1rem;
  text-decoration: none;
  display: flex;
}

.attrib-heb {
  font-family: Amatic SC, sans-serif;
  font-size: 1.8rem;
}

.cart-container {
  width: 25%;
  display: flex;
}

.shoppingcart-svg {
  flex-flow: row;
  justify-content: flex-start;
  width: 1.4rem;
  display: flex;
}

.cart-number {
  border: 1px solid #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  font-size: .8rem;
  display: flex;
  position: relative;
  left: -9%;
}

.ul-heb {
  flex-flow: row-reverse;
  display: flex;
}

.categories-list {
  text-align: center;
  opacity: 0;
  z-index: -1;
  background-color: #faebd7e6;
  flex-flow: column;
  justify-content: space-evenly;
  width: 20%;
  list-style: none;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: linear;
  display: flex;
  position: absolute;
  top: -20rem;
}

.categories-list--active {
  opacity: 1;
  z-index: 1;
  transform: translateY(25.6rem);
}

.category-item {
  height: 50px;
  position: relative;
}

.category-item:hover {
  cursor: pointer;
  background-color: #ffffff80;
}

.menu.sticky {
  z-index: 8;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  transition: all .5s;
  display: flex;
  position: fixed;
}

.menu.hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.shoppingcart-svg:hover {
  fill: #79ddff;
}

.icons-container {
  display: none;
}

.main-nav-tab.login {
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-btn {
  border: 1px solid;
  border-radius: 10px;
  width: 100px;
  height: 30px;
  margin: 5px;
}

.login-btn:hover {
  color: #0ff;
  cursor: pointer;
}

.languages-container {
  border-left: 2px solid gray;
  align-self: center;
  width: 6rem;
  height: 3rem;
  padding: 10px;
  display: flex;
}

.languages-container button {
  background-color: #fff;
  border: none;
  font-family: Amatic SC, sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
}

.languages-container button:first-child {
  border-right: 1px solid;
  padding-right: 5px;
}

.languages-container button:last-child {
  padding-left: 5px;
}

.languages-container button:hover {
  cursor: pointer;
  color: gray;
}

.modal-section {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.modal {
  display: none;
}

.modal-reveal {
  text-align: center;
  opacity: 1;
  z-index: 10;
  background-image: url("main-photo.a72f0789.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: column;
  align-items: center;
  width: 80vw;
  height: 80vh;
  margin-top: 60px;
  font-size: 2rem;
  transition: all .5s ease-in;
  display: none;
  position: fixed;
}

.subscribenow-text {
  background-color: #ffffffa9;
  border-radius: 15px;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}

.subscribe-details {
  background-color: #ffffffa9;
  border-radius: 2rem;
  flex-flow: row;
  align-items: center;
  width: auto;
  margin-top: 30px;
  margin-bottom: 2rem;
  padding: 10px;
  display: flex;
}

.subscribe-label {
  border-radius: 5%;
  margin-right: 20px;
  padding: 5px;
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
}

#subscribe-email {
  border-radius: 5rem;
  width: 15rem;
  height: 1.5rem;
  margin-right: 20px;
  padding: 15px;
  font-size: 1rem;
}

#submit-subscribe {
  cursor: pointer;
  background: none;
  border-radius: 2rem;
  width: 5rem;
  height: 2rem;
  margin-right: 10px;
}

#submit-subscribe:hover {
  background-color: #ffffff80;
  font-size: .9rem;
}

#subscribe-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40vw;
  height: 40vh;
}

.overlay-reveal {
  opacity: 1;
  z-index: 9;
  background-color: #fdfbfbcc;
  width: 100%;
  height: 100%;
  transition: all .5s ease-in;
  position: fixed;
  top: 0;
}

.close-modal, .close-thanks {
  color: #000;
  cursor: pointer;
  background-color: #fff0;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  position: absolute;
  top: -2rem;
  right: -2rem;
}

h1 {
  width: 95%;
  margin: 30px 0;
  font-family: Raleway, sans-serif;
  font-size: 2.5rem;
}

h3 {
  font-variant: small-caps;
  color: #ffffffd5;
  width: 35%;
  font-size: clamp(1.2rem, 5vw, 5rem);
  font-weight: lighter;
  display: none;
  position: absolute;
  top: 30%;
  left: 65%;
}

h3 span {
  color: #90f2ea;
  border-radius: 5px;
  padding: 5px;
  font-weight: 700;
}

.hide {
  display: none;
}

.thanks {
  text-align: center;
  z-index: 10;
  background-color: #fffc;
  width: 40vw;
  height: 40vh;
  margin-top: 60px;
  padding-top: 10px;
  font-size: 2rem;
  position: absolute;
  top: 20%;
}

p {
  font-size: 1.3rem;
}

main {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
}

.slider-container {
  flex-flow: row;
  align-items: center;
  width: 95%;
  height: 65%;
  margin-top: 5rem;
  display: flex;
  position: relative;
}

.slider-wrapper .img-list .slider-image-item {
  object-fit: cover;
  height: 100%;
}

.slider-wrapper {
  flex-flow: row;
  justify-content: center;
  width: 100%;
  height: 90%;
  display: flex;
  overflow-x: hidden;
}

.img-list {
  flex-flow: row;
  width: 100%;
  display: flex;
}

.slider-image-item {
  width: 33%;
  padding: 5px;
  transition: transform 1s;
}

.slider_btns {
  cursor: pointer;
  color: #6c6c6c;
  background-color: #fffada80;
  border: .5px solid #c6aa767a;
  width: 2.5%;
  height: 75%;
  font-size: 2rem;
  position: absolute;
  top: 15%;
}

.slider-btn--left {
  left: 1.5%;
}

.slider-btn--right {
  right: 2.5%;
}

.slider_btns:hover {
  background-color: #e8e8e899;
}

.categories-container {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 95%;
  margin-top: 50px;
  display: grid;
  box-shadow: 0 0 50px -10px #00000094;
}

.category-box {
  justify-content: center;
  height: auto;
  padding: 1rem;
  text-decoration: none;
  display: flex;
  position: relative;
}

.large-img-item {
  width: 100%;
  height: 100%;
}

.category-name {
  color: #000;
  letter-spacing: .3em;
  text-align: center;
  white-space: nowrap;
  background-color: #fffffff2;
  width: auto;
  padding: 5px;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  top: 80%;
}

.go-to-top {
  color: #3e3e3e;
  background-color: #000;
  border: none;
  border-radius: 50%;
  align-self: center;
  width: 2rem;
  height: 2rem;
  margin: 4rem;
  padding: 5px;
  font-size: 2rem;
  font-style: italic;
  position: relative;
  top: 2rem;
}

.go-to-top:hover {
  cursor: pointer;
  background-color: #6f86a960;
}

.footer {
  background-color: #a1a0413f;
  width: 100%;
}

.columns-container {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.footer-right-column, .footer-middle-column, .footer-left-column {
  flex-flow: column;
  align-items: center;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
}

.attrib-footer {
  color: #000;
  border-bottom: .5px solid #424242;
  flex-flow: row;
  align-items: center;
  width: auto;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
}

.attrib-footer:hover {
  color: #000;
}

.rights-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
}

.rights-text {
  color: #828282;
}

.whatsapp-container {
  z-index: 9;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 89%;
}

.whatsapp-atr {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #3c8f25;
  border-radius: 10%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.whatsapp-svg {
  justify-self: self-end;
  width: 40px;
  height: 40px;
}

.hide-lng {
  display: none;
}

.lng--active, .columns-container_heb {
  display: flex;
}
/*# sourceMappingURL=index.1a04c401.css.map */
