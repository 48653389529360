body {
  display: flex;
  flex-flow: column;
  /* width: 100%; */
  height: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid black; */
}

header {
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  width: 100%;
  height: 95vh;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid black; */
}

.hero-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 70%;
  position: relative;
  top: 18%;
  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid darkorange; */
}

.main-image {
  object-fit: cover;
  width: 100%;
  height: 100%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid rgb(177, 20, 143); */
}

.logo {
  /* display: block; */
  width: 15%;
  margin-bottom: 1.5rem;
  /* height: 10%; */

  /* position: absolute;
  left: 3%;
  bottom: 65%; */

  z-index: 0;

  /* border: 2px solid rgb(230, 37, 16); */
}

/* ~~~~~~ Mobile Menu ~~~~~~~ */

.menubars-use {
  display: none;
}

#menubars-svg {
  display: none;
}

#show-menu-button {
  display: none;
}

/* ````` Mobile Menu END ``````` */

/* ~~~~~~~ Desktop Navbar ~~~~~~~~~ */

.menu {
  display: flex;
  width: 100%;
  height: 15%;
  z-index: 7;
  background-color: rgba(255, 255, 255);

  position: fixed;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(20, 177, 35); */
}

.menu__ul {
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: rgba(255, 255, 255);
  width: 100%;
  height: 100%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(228, 152, 37); */
}

.main-nav-tab {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  list-style: none;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(46, 37, 228); */
}

.main-nav-tab:hover {
  cursor: pointer;
}

.attrib,
a {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 3rem;
  color: rgb(78, 78, 78);
  font-size: 1rem;
  font-family: Raleway, sans-serif;
  /* font-size: 1rem;
  font-family: 'Poiret One', sans-serif; */
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(40, 228, 37); */
}

.attrib:hover {
  color: rgb(152, 152, 152);
}


.attrib-cart {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 3rem;
  color: black;
  font-size: 1.1rem;
  font-family: Raleway, sans-serif;
  text-decoration: none;

  /* border: 1px solid green; */
}
.attrib-heb {
  font-size: 1.8rem;
  font-family: 'Amatic SC', sans-serif;
}
.cart-container {
  display: flex;
  width: 25%;

  /* border: 1px solid red; */
}

.shoppingcart-svg {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 1.4rem;

  /* border: 1px solid; */
}

.cart-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: relative;
  left: -9%;
  width: 14px;
  height: 14px;
  border: 1px solid black;
  border-radius: 50%;
}

.ul-heb {
  display: flex;
  flex-flow: row-reverse;
}

/* @keyframes fillit {
  0% {
    background-color: none;
    color: black;
  }

  100% {
    background-color: rgb(0, 0, 0);
    color: white;
  }
} */

.categories-list {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  text-align: center;
  list-style: none;
  position: absolute;
  top: -20rem;
  width: 20%;

  background-color: rgba(250, 235, 215, 0.9);
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 0;
  z-index: -1;
}

.categories-list--active {
  opacity: 1;
  z-index: 1;
  transform: translateY(25.6rem);
}

.category-item {
  height: 50px;
  position: relative;
}

.category-item:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.menu.sticky {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 8;

  transition: all;
  transition-duration: 500ms;
  transition-timing-function: ease;
  position: fixed;
}

.menu.hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.shoppingcart-svg:hover {
  fill: rgb(121, 221, 255);
}

.icons-container {
  display: none;
}

.main-nav-tab.login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn {
  width: 100px;
  height: 30px;
  margin: 5px;
  border: 1px solid;
  border-radius: 10px;
}

.login-btn:hover {
  color: aqua;
  cursor: pointer;
}

.languages-container {
  display: flex;
  align-self: center;
  padding: 10px;
  height: 3rem;
  width: 6rem;

  border-left: 2px solid gray;

  /* border: 1px solid red; */
}

.languages-container button {
  background-color: white;
  border: none;
  font-size: 1rem;
  font-family: 'Amatic SC', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
}

.languages-container button:first-child {
  border-right: 1px solid;
  padding-right: 5px;
}

.languages-container button:last-child {
  padding-left: 5px;
}

.languages-container button:hover {
  cursor: pointer;
  color: gray;
}
/* ```````Navbar END`````````` */

.modal-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* justify-content: center; */
}

.modal {
  display: none;
}
.modal-reveal {
  display: none;
  flex-flow: column;
  align-items: center;

  width: 80vw;
  height: 80vh;
  position: fixed;
  margin-top: 60px;
  font-size: 2rem;
  text-align: center;
  background-image: url(../imgs/main-photo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  z-index: 10;

  transition: all;
  transition-duration: 500ms;
  transition-timing-function: ease-in;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid red; */
}

.subscribenow-text {
  width: auto;
  background-color: rgba(255, 255, 255, 0.662);
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
}

.subscribe-details {
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.662);
  padding: 10px;
  border-radius: 2rem;
  margin-bottom: 2rem;

  margin-top: 30px;
  width: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid green; */
}

.subscribe-label {
  font-family: Raleway, sans-serif;
  margin-right: 20px;
  font-size: 1.5rem;
  padding: 5px;
  border-radius: 5%;
}

#subscribe-email {
  margin-right: 20px;
  width: 15rem;
  height: 1.5rem;
  font-size: 1rem;
  border-radius: 5rem;
  padding: 15px;
}

#submit-subscribe {
  width: 5rem;
  height: 2rem;
  cursor: pointer;
  background: none;
  margin-right: 10px;
  border-radius: 2rem;
}

#submit-subscribe:hover {
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

#subscribe-image {
  width: 40vw;
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.overlay-reveal {
  /* display: none; */
  width: 100%;
  height: 100%;
  background-color: rgba(253, 251, 251, 0.8);
  position: fixed;
  top: 0px;
  opacity: 1;

  transition: all;
  transition-duration: 500ms;
  transition-timing-function: ease-in;

  z-index: 9;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid green; */
}

.close-modal,
.close-thanks {
  color: black;
  height: 40px;
  width: 40px;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  position: absolute;
  right: -2rem;
  top: -2rem;
  border: none;
}

h1 {
  font-family: Raleway, sans-serif;
  margin: 30px 0px;
  width: 95%;
  font-size: 2.5rem;
}

h3 {
  display: none;
  position: absolute;
  top: 30%;
  left: 65%;
  width: 35%;
  /* height: 25%; */
  font-size: clamp(1.2rem, 5vw, 5rem);
  font-variant: small-caps;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.836);

  /* border: 1px solid darkblue; */
}

h3 span {
  font-weight: 700;
  padding: 5px;
  border-radius: 5px;
  color: #b1275f;
  color: #90f2ea;
}

.hide {
  display: none;
}

.thanks {
  /* display: flex;
    flex-flow: column wrap;
    align-items: center; */

  width: 40vw;
  height: 40vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 20%;
  margin-top: 60px;
  padding-top: 10px;
  font-size: 2rem;
  text-align: center;

  z-index: 10;

  /* border: 3px solid red; */
}

p {
  font-size: 1.3rem;
}

main {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 10px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(40, 228, 37); */
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ----------- Slider ---------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.slider-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 95%;
  height: 65%;
  position: relative;
  margin-top: 5rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(0, 217, 255); */
}

.slider-wrapper .img-list .slider-image-item {
  height: 100%;
  object-fit: cover;
}

.slider-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
  height: 90%;
  overflow-x: hidden;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(179, 0, 255); */
}

.img-list {
  display: flex;
  flex-flow: row;
  width: 100%;
  /* height: 300px; */
}

.slider-image-item {
  padding: 5px;
  /* width: calc(100% / 3); */
  width: 33%;
  /* height: 100%; */
  transition: transform 1s;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(9, 255, 0); */
}

.slider_btns {
  position: absolute;
  top: 15%;
  height: 75%;
  width: 2.5%;
  background-color: rgba(255, 250, 218, 0.5);
  border: 0.5px solid rgba(198, 170, 118, 0.477);
  cursor: pointer;
  font-size: 2rem;
  color: rgb(108, 108, 108);
}

.slider-btn--left {
  left: 1.5%;
}

.slider-btn--right {
  right: 2.5%;
}

.slider_btns:hover {
  background-color: rgba(232, 232, 232, 0.6);
}

/* ----------- Slider end ----------------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.categories-container {
  display: grid;

  grid-template-columns: 1fr 1fr;

  row-gap: 10px;
  column-gap: 10px;
  width: 95%;
  margin-top: 50px;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.58);

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid red; */
  /* grid-column: 1/2; */
}

.category-box {
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  padding: 1rem;
  height: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid red; */
}

.large-img-item {
  width: 100%;
  height: 100%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(89, 0, 255); */
}

.category-name {
  display: inline-block;
  /* justify-content: center; */

  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  /* font-family: Raleway, sans-serif; */
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.3em;

  position: absolute;
  top: 80%;
  text-align: center;
  white-space: nowrap;
  width: auto;
  padding: 5px;
}

.go-to-top {
  position: relative;
  top: 2rem;
  width: 10%;
  border: none;
  background-color: rgba(89, 107, 134, 0.377);
  padding: 5px;
  margin-bottom: 60px;
  margin-top: 60px;
  border-radius: 5px;
  font-style: italic;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(89, 0, 255); */
}

.go-to-top {
  align-self: center;

  width: 2rem;
  height: 2rem;
  margin: 4rem;
  font-size: 2rem;
  border: none;
  border-radius: 50%;
  /* background-image: linear-gradient(
    rgba(255, 166, 0, 0.653),
    rgba(255, 0, 0, 0.553)
  ); */
  background-color: black;
  padding: 5px;
  color: rgb(62, 62, 62);

  /* ~~~~ FOR DEBUGGING~~~~ */
  /* border: 2px solid rgb(37, 155, 228); */
}

.go-to-top:hover {
  cursor: pointer;
  background-color: rgba(111, 134, 169, 0.377);
}

.footer {
  width: 100%;
  background-color: rgba(161, 160, 65, 0.249);

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(4, 255, 0); */
}

.columns-container {
  display: flex;
  justify-content: space-around;
  width: 100%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(4, 255, 0); */
}

.footer-right-column,
.footer-middle-column,
.footer-left-column {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 30px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(89, 0, 255); */
}

.attrib-footer {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  color: black;
  border-bottom: 0.5px solid rgb(66, 66, 66);
  font-size: 1rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(40, 228, 37); */
}

.attrib-footer:hover {
  color: black;
}

.rights-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 1px solid rgb(210, 28, 140); */
}

.rights-text {
  color: rgb(130, 130, 130);

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 1px solid green; */
}

.whatsapp-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 9;
  top: 89%;
  width: 100%;
  /* border: 2px solid red; */
}

.whatsapp-atr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(60, 143, 37);
  border-radius: 10%;
  z-index: 10;
  /* margin-right: 5%; */
}

.whatsapp-svg {
  width: 40px;
  height: 40px;
  justify-self: self-end;
  /* border: 1px solid red; */
}

.hide-lng {
  display: none;
}

.lng--active {
  display: flex;
}

.columns-container_heb {
  display: flex;
}
